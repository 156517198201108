export class PersonEntity {
  _id!: string;
  updatedAt?: Date;
  createdAt?: Date;

  // Permissions
  adminPermissions?: AdminUserPermission[];

  // Person Type
  type?: PersonTypeEnum;

  // Person status
  status?: PersonStatusEnum;

  // Personal Info
  phone!: string;
  secondaryPhone?: string;
  fullname?: string; // IMPORTANT: need to have a at least empty value for the person search to work
  nameFields?: NameFields;
  /**
   * @depracated Use full_address
   */
  address?: string;
  email?: string;
  full_address?: string;
  full_address_latitude?: number;
  full_address_longitude?: number;

  // General purpose
  hasMet?: boolean;
  rating?: string;
  comments?: string;

  // Payments
  paymentInfo?: string;

  // SMS Related
  lastbatchsenttime?: Date;
  no_mass_text?: boolean;

  unreachablePhone?: boolean;

  // Worker Specific Info
  order?: number;
  drive_rate?: number;
  yearsOfExperience?: number;
  hiredbefore?: boolean;
  isHighQuality?: boolean;
  vehicle?: string;
  jobType?: JobTypeEnum[];
  vehicleType?: VehicleType[];
  workingDays?: DaysEnum[];
  vehicleDetail?: string;
  snowTools?: SnowTool[];
  landscapingTools?: Array<LandscapingTool>;
  handymanTools?: Array<HandymanTool>;
  workerPreferences?: WorkerPreferences;
  toolImageURLs?: string[];
  fileURLs?: string[];
  pastWorkURLs?: string[];
  languages?: string[];
  acknowledged?: Acknowledgements;
  hourly_rate?: string;
  profession?: Array<Profession>;
  otherSkills?: string;
  countOfSalt?: number;
  hasSalt?: string;
  hasAutoInsurance?: boolean;
  autoInsuranceImageURLs?: string[];
  vehicleImageURLs?: string[];
  hasDriverLicence?: boolean;
  driverLicenceImageURLs?: string[];
  toolsAdditionalInfo?: string;
  preferencesAdditionalInfo?: string;
  landscapingToolsObject?: LandscapingToolsObject;
  snowToolsObject?: SnowToolsObject;
  handymanToolsObject?: HandymanToolsObject;

  // Landlord Specific Info
  website?: string;

  // landlords are collected from two different sources
  landlordDataSource?: LandlordSourceEnum;

  // Customer Specific Info
  pricingPlan?: PricingPlanEnum;

  lastLoggedInAt?: Date;

  visitOrderOfLatestPicUpload?: number;
}

export type StatusSeverity = 'error' | 'warning' | 'success' | 'default';

export enum PersonTypeEnum {
  Customer = 'customer',
  Worker = 'worker',
  Agent = 'agent',
  Admin = 'admin',
  Landlord = 'landlord',
}
export const UNKNOWN_PERSON_TYPE = 'unknown';

export enum LandlordSourceEnum {
  ROGER = 'roger',
  YGL = 'ygl',
}

export enum PricingPlanEnum {
  MONTHLY_UNLIMITED = 'monthly_unlimited',
  PER_SNOWSTORM = 'per_snowstorm',
}

export const PricingPlanList = [PricingPlanEnum.MONTHLY_UNLIMITED, PricingPlanEnum.PER_SNOWSTORM];

export enum PersonStatusEnum {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  PENDING_APPROVAL = 'pending_approval',
  BLOCKED = 'blocked',
  DELETED = 'deleted',
  INSUFFICIENT = 'insufficient',
}

export const PersonStatusSeverity: Partial<Record<PersonStatusEnum, StatusSeverity>> = {
  [PersonStatusEnum.ACTIVE]: 'success',
  [PersonStatusEnum.PENDING_APPROVAL]: 'warning',
  [PersonStatusEnum.INSUFFICIENT]: 'error',
};

export const PersonStatusList = [
  PersonStatusEnum.ACTIVE,
  PersonStatusEnum.INACTIVE,
  PersonStatusEnum.PENDING_APPROVAL,
  PersonStatusEnum.INSUFFICIENT,
  PersonStatusEnum.BLOCKED,
];

export enum PersonAcknowledgementsEnum {
  CONTRACTORWORK = 'contractorWork',
  OFLEGALAGE = 'ofLegalAge',
  PAYMENTMETHODS = 'paymentMethods',
  WILLCONTACT = 'willContact',
  REQUESTTIME = 'requestTime',
  WILLCONTACTWHENREADY = 'willContactWhenReady',
  SALTINGTIME = 'saltingTime',
  BEFORESTART = 'beforeStart',
  ISSUEREPORT = 'issueReport',
  SMARTPHONE = 'smartphone',
  ARRIVALTIME = 'arrivalTime',
  BEFOREANDAFTERPIC = 'beforeAndAfterPic',
  PAYMENTAGREEMENT = 'paymentAgreement',
  SALTLOAD = 'saltLoad',
  SALTPURCHASE = 'saltPurchase',
  SHOVEL = 'shovel',
  SNOWBLOWER = 'snowBlower',
  HELPERS = 'helpers',
  COMPLETETHEJOB = 'completeTheJob',
  MOREADDRESSES = 'moreAddresses',
  ENDOFTHEDAY = 'endOfTheDay',
  RUNOUTOFSALT = 'runOutOfSalt',
  SERVICEAREA = 'serviceArea',
}

export const PersonAcknowledgementsList = [
  PersonAcknowledgementsEnum.CONTRACTORWORK,
  PersonAcknowledgementsEnum.OFLEGALAGE,
  PersonAcknowledgementsEnum.PAYMENTMETHODS,
  PersonAcknowledgementsEnum.WILLCONTACT,
  PersonAcknowledgementsEnum.REQUESTTIME,
  PersonAcknowledgementsEnum.WILLCONTACTWHENREADY,
  PersonAcknowledgementsEnum.SALTINGTIME,
  PersonAcknowledgementsEnum.BEFORESTART,
  PersonAcknowledgementsEnum.ISSUEREPORT,
  PersonAcknowledgementsEnum.SMARTPHONE,
  PersonAcknowledgementsEnum.ARRIVALTIME,
  PersonAcknowledgementsEnum.BEFOREANDAFTERPIC,
  PersonAcknowledgementsEnum.PAYMENTAGREEMENT,
  PersonAcknowledgementsEnum.SALTLOAD,
  PersonAcknowledgementsEnum.SALTPURCHASE,
  PersonAcknowledgementsEnum.SHOVEL,
  PersonAcknowledgementsEnum.SNOWBLOWER,
  PersonAcknowledgementsEnum.HELPERS,
  PersonAcknowledgementsEnum.COMPLETETHEJOB,
  PersonAcknowledgementsEnum.MOREADDRESSES,
  PersonAcknowledgementsEnum.ENDOFTHEDAY,
  PersonAcknowledgementsEnum.RUNOUTOFSALT,
  PersonAcknowledgementsEnum.SERVICEAREA,
];

export enum AdminUserPermission {
  // super admin
  SUPER_ADMIN = 'full_access',
  // page permissions
  PAGE_SMS = 'admin:page:sms',
  PAGE_PERSON = 'admin:page:person',
  PAGE_INQUIRY = 'admin:page:inquiry',
  PAGE_ADDRESS = 'admin:page:address',
  PAGE_VISIT = 'admin:page:visit',
  PAGE_MAP = 'admin:page:map',
  // person page permissions
  PERSON_LIST_CUSTOMER = 'person-list:customer',
  PERSON_LIST_AGENT = 'person-list:agent',
  PERSON_LIST_WORKER = 'person-list:worker',
  PERSON_LIST_LANDLORD = 'person-list:landlord',
  PERSON_LIST_ADMIN = 'person-list:admin',
  PERSON_UPDATE = 'person:update',
  PERSON_DELETE = 'person:delete',
  // mass text permission
  MASS_TEXT = 'mass-test',
}

export const PAGE_PERMISSIONS: AdminUserPermission[] = [
  AdminUserPermission.PAGE_PERSON,
  AdminUserPermission.PAGE_SMS,
  AdminUserPermission.PAGE_INQUIRY,
  AdminUserPermission.PAGE_ADDRESS,
  AdminUserPermission.PAGE_VISIT,
  AdminUserPermission.PAGE_MAP,
];

// Update resources.json if you change values in this enum.
export enum VehicleType {
  SEDAN = 'sedan',
  SUV = 'suv',
  VAN = 'van',
  TRUCK = 'truck',
  NO_VEHICLE = 'no_vehicle',
}

export const VehicleTypes = [
  VehicleType.SEDAN,
  VehicleType.SUV,
  VehicleType.VAN,
  VehicleType.TRUCK,
  VehicleType.NO_VEHICLE,
];

// Update resources.json if you change values in this enum.
export enum SnowTool {
  SHOVEL = 'shovel',
  SNOWBLOWER = 'snowblower',
  PLOW_TRUCK = 'plowtruck',
  SALT = 'salt',
}
export const SnowTools = [SnowTool.SHOVEL, SnowTool.SNOWBLOWER, SnowTool.PLOW_TRUCK, SnowTool.SALT];

export enum HandymanTool {
  DRILL = 'drill',
  HAMMER = 'hammer',
  LADDER = 'ladder',
  CIRCULAR_SAW = 'circularsaw',
  UTILITY_KNIFE = 'utilityknife',
}

export const HandymanTools = [
  HandymanTool.DRILL,
  HandymanTool.HAMMER,
  HandymanTool.LADDER,
  HandymanTool.CIRCULAR_SAW,
  HandymanTool.UTILITY_KNIFE,
];

// Update resources.json if you change values in this enum.
export enum Profession {
  STUDENT = 'student',
  CLEANER = 'cleaner',
  HANDYMAN = 'handyman',
  CONSTRUCTION = 'construction',
  SECURITY = 'security',
  MECHANIC = 'mechanic',
  ELECTRICIAN = 'electrician',
  FOOD_SERVICE = 'food_service_restaurant_bar',
  DRIVER_DELIVERY = 'driver_delivery',
  PAINTING = 'painting',
  MASONRY = 'masonry',
  CARPENTER = 'carpenter',
  LANDSCAPING = 'landscaping',
  WAREHOUSE = 'warehouse',
  LABORER = 'laborer',
  WASTE_MANAGEMENT = 'waste_management',
  HEALTHCARE = 'healthcare',
  MAINTENANCE = 'maintenance',
  RETAIL_SHOP = 'retail_shop',
  BANK_WORKER = 'work_in_a_bank',
  EDUCATION = 'education',
  HAIRDRESSING_BEAUTY = 'hairdressing_and_beauty_services',
  OTHER = 'other',
}

// Update resources.json if you change values in this enum.
export enum LandscapingTool {
  MOWER = 'mower',
  WEEDWACKER = 'weedwhacker',
  RAKE = 'rake',
  BROOM = 'broom',
  BLOWER = 'blower',
  TRIMMER = 'trimmer',
}

export const LandscapingTools = [
  LandscapingTool.BLOWER,
  LandscapingTool.BROOM,
  LandscapingTool.MOWER,
  LandscapingTool.RAKE,
  LandscapingTool.TRIMMER,
  LandscapingTool.WEEDWACKER,
];

// Update resources.json if you change values in this enum.
export enum Language {
  ENGLISH = 'english',
  PORTUGUESE = 'portuguese',
  SPANISH = 'spanish',
}

export type LanguageCodesType = 'en' | 'es' | 'pt';

export const LanguageCodes: Record<Language, LanguageCodesType> = {
  [Language.ENGLISH]: 'en',
  [Language.PORTUGUESE]: 'pt',
  [Language.SPANISH]: 'es',
};

export const Languages = [Language.ENGLISH, Language.PORTUGUESE, Language.SPANISH];

// Update resources.json if you change values in this enum.
export enum DaysEnum {
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
  SUNDAY = 'sunday',
}

export enum SaltStatusEnum {
  YES = 'yes',
  NO_NEED_REIMBURSEMENT = 'no_need_reimbursement',
  NO_NEED_SUPPLY = 'no_need_supply',
}

export const SaltStatus: Record<SaltStatusEnum, string> = {
  [SaltStatusEnum.YES]: 'Yes',
  [SaltStatusEnum.NO_NEED_REIMBURSEMENT]: 'No, will buy and request',
  [SaltStatusEnum.NO_NEED_SUPPLY]: "No, need Roger's supply",
};

export const Days = [
  DaysEnum.MONDAY,
  DaysEnum.TUESDAY,
  DaysEnum.WEDNESDAY,
  DaysEnum.THURSDAY,
  DaysEnum.FRIDAY,
  DaysEnum.SATURDAY,
  DaysEnum.SUNDAY,
];

export enum JobTypeEnum {
  Snow = 'snow',
  Landscaping = 'landscaping',
  Handyman = 'handyman',
}

export const JobTypeList = [JobTypeEnum.Snow, JobTypeEnum.Landscaping, JobTypeEnum.Handyman];

export type WorkerPreferences = Partial<{
  canGiveRide: boolean;
  canBuySalt: boolean;
  worksAlone: boolean;
}>;

export type LandscapingToolsObject = Partial<Record<LandscapingTool, string[]>>;
export type SnowToolsObject = Partial<Record<SnowTool, string[]>>;
export type HandymanToolsObject = Partial<Record<HandymanTool, string[]>>;

export type NameFields = Partial<{
  firstName: string;
  middleName?: string;
  lastName: string;
}>;

export type Acknowledgements = Partial<{
  contractorWork: string;
  ofLegalAge: string;
  paymentMethods: string;
  willContact: string;
  requestTime: string;
  willContactWhenReady: string;
  saltingTime: string;
  beforeStart: string;
  issueReport: string;
  smartphone: string;
  arrivalTime: string;
  beforeAndAfterPic: string;
  paymentAgreement: string;
  saltLoad: string;
  saltPurchase: string;
  shovel: string;
  snowBlower: string;
  helpers: string;
  completeTheJob: string;
  moreAddresses: string;
  endOfTheDay: string;
  runOutOfSalt: string;
  serviceArea: string;
}>;
