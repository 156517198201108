import { Alert } from '@repo/mui/Alert';
import { TextField } from '@repo/mui/TextField';
import { Typography } from '@repo/mui/Typography';
import { Button } from '@repo/mui/Button';
import { Stack } from '@repo/mui/Stack';
import { useController, useForm } from '@repo/form/rhf';
import { useTranslation } from '@repo/translation/react-i18next';
import { translatePhoneError } from '../../utils/translatePhoneError.ts';
import { formatPhoneNumber } from '@repo/utils/formatPhoneNumber';
import { useRouter } from '@repo/routing';
import { Pages } from '../../utils/constants.tsx';
import { useState } from 'react';
import { LoadingBackdrop } from '../../components/LoadingBackdrop.tsx';
import { loginAction } from '../../actions/loginAction.ts';

export function LoginForm() {
  const { t } = useTranslation('workerLoginPage');
  const {
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm<{ phone: string; captchaToken: string }>({ mode: 'all' });
  const [isLoading, setIsLoading] = useState(false);
  const router = useRouter();
  const [error, setError] = useState('');

  const handleLogin = async () => {
    await handleSubmit(async (data) => {
      try {
        setIsLoading(true);
        const phoneNumbersOnly = data.phone.replace(/\D/g, '');
        const res = await loginAction({ phone: phoneNumbersOnly });
        if (res.notification) {
          handleError(res.notification);
          return;
        }
        router.push(Pages.Home);
      } catch (error) {
        setIsLoading(false);
        setError('Login failed');
      }
    })();
  };

  const handleError = (notification: string) => {
    setError(notification);
    setIsLoading(false);
  };

  const phoneError = translatePhoneError(errors.phone, t);

  const { field: phoneField } = useController({
    name: 'phone',
    control,
    rules: {
      required: true,
      minLength: 14,
      maxLength: 14,
    },
  });

  return (
    <>
      <LoadingBackdrop open={isLoading} />
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 10 }}>
        <Stack spacing={1} sx={{ width: 'calc(100% - 24px)', maxWidth: 360 }}>
          <TextField
            label={t('inputLabel')}
            variant='outlined'
            onChange={(e) => {
              const value = e.target.value;
              const valueWithoutSpecialCharacters = value.replace(/\D/g, '');
              const formattedValue = formatPhoneNumber(valueWithoutSpecialCharacters);
              phoneField.onChange(formattedValue);
            }}
            onBlur={phoneField.onBlur}
            value={formatPhoneNumber(watch('phone'))}
            error={Boolean(phoneError)}
            helperText={phoneError || t('inputHelper')}
            slotProps={{ input: { inputMode: 'numeric' } }}
          />
          <Button type='submit' variant='contained' onClick={handleLogin}>
            {t('submitButton')}
          </Button>
          {error && <Alert severity='error'>{error}</Alert>}
          <div>or you haven't registered, please signup:</div>
          <Button
            href={Pages.SignUpPhone}
            variant='contained'
            style={{ textDecoration: 'none', color: '#fff' }}>
            {t('signUp')}
          </Button>
          <Alert severity='info'>
            <Typography variant='body2' color='secondary'>
              {t('loginInfo')}
            </Typography>
          </Alert>
        </Stack>
      </div>
    </>
  );
}
